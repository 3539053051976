






























































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import CTagsInput from '~/components/shared/configurable/form/tag/CTagsInput.vue'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import { AutocompleteSuggestion } from '~/models/form/autocomplete'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { debounce } from '~/utils/function'
import { useId } from '~/compositions/id'
import { useDep } from '~/compositions/dependency-container'
import ScrollService from '~/services/scroll/ScrollService'
import { InputSize } from '~/models/app/input'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  props: {
    minQueryCharacters: {
      type: Number,
      default: 3
    },
    suggestions: {
      type: Array as PropType<AutocompleteSuggestion[]>,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    multiselectValues: {
      type: Array as PropType<AutocompleteSuggestion[]>,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    inputDebounce: {
      type: Number,
      default: 50
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    multiselectMax: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    dropdownDisabled: {
      type: Boolean,
      default: false
    },
    selectFirstOnClose: {
      type: Boolean,
      default: false
    }
  },
  components: { CTagsInput, CDropdown },
  setup(props, { emit }) {
    const {
      minQueryCharacters,
      suggestions,
      value,
      multiselect,
      multiselectValues,
      inputDebounce,
      size,
      multiselectMax
    } = toRefs(props)
    const dropdownIsVisible = ref(false)
    const query = ref(value.value || '')
    const focusedSuggestionIndex = ref(0)
    const internalSuggestions = ref<AutocompleteSuggestion[]>(suggestions.value)
    const internalMultiselectValues = ref<AutocompleteSuggestion[]>(
      multiselectValues.value
    )
    const multiselectInputTemplateRef = ref<HTMLElement | null>(null)
    const singleSelectInputTemplateRef = ref<HTMLElement | null>(null)
    const dropdownTemplateRef = ref<any>(null)
    const suggestionRefs = ref<HTMLElement[]>([])

    const loading = ref(true)
    const { createRandomId } = useId()
    const id = createRandomId()

    const scrollService = useDep(ScrollService)

    watch(suggestions, newSuggestions => handleNewSuggestions(newSuggestions))

    watch(
      multiselectValues,
      newMultiselectValues =>
        (internalMultiselectValues.value = newMultiselectValues)
    )
    watch(value, newTextValue => (query.value = newTextValue || ''))

    function onInput(text: string) {
      setQuery(text)
      focusedSuggestionIndex.value = 0

      if (queryAboveMinCharacters.value) {
        requestSuggestions()
        showDropdown()
      } else {
        hideDropdown()
      }
    }
    function onFocus() {
      focusedSuggestionIndex.value = 0

      if (internalSuggestions.value.length > 0) {
        showDropdown()
      } else if (queryAboveMinCharacters.value) {
        requestSuggestions()
        showDropdown()
      }
    }

    function hideDropdown() {
      dropdownIsVisible.value = false
      focusedSuggestionIndex.value = 0
    }

    function showDropdown() {
      dropdownIsVisible.value = true
    }

    const queryAboveMinCharacters = computed(
      () => query.value.length >= minQueryCharacters.value
    )
    const suggestionsExist = computed(
      () => internalSuggestions.value.length > 0
    )

    function onClickOutside(event: PointerEvent) {
      const clickedOnDropdown = event
        .composedPath()
        // FIXME: This is super hacky, maybe try to find another way to check if the user has clicked in the dropdown
        .some((p: any) => p.classList?.contains('v-popper__popper'))
      if (!clickedOnDropdown) {
        hideDropdown()

        if (props.selectFirstOnClose && props.suggestions.length > 0) {
          selectSuggestion(0)
        }
      }
    }

    function getSuggestionClass(index: number) {
      const c = []
      if (suggestionIsFocused(index)) {
        c.push('tw-bg-grey-200')
      }

      return c
    }

    function suggestionIsFocused(index: number) {
      return index === focusedSuggestionIndex.value
    }

    function focusAdjacentSuggestion(offset: number) {
      if (!dropdownIsVisible) {
        return
      }

      let nextIndex = focusedSuggestionIndex.value + offset
      // if the user has crossed the boundary upwards, select the last
      if (nextIndex < 0) {
        nextIndex = internalSuggestions.value.length - 1
      }
      // if the user has crossed the boundary downwards, select the first
      else if (nextIndex + 1 > internalSuggestions.value.length) {
        nextIndex = 0
      }
      focusedSuggestionIndex.value = nextIndex

      const suggestionElement: HTMLElement =
        suggestionRefs.value[focusedSuggestionIndex.value]
      const resultsContainer =
        dropdownTemplateRef.value?.$refs.dropdownTemplateRef?.$refs
          ?.popperContent?.$refs?.inner

      scrollService.scrollIfOverflownVertical(
        suggestionElement,
        resultsContainer
      )
    }

    function onEnterKeyPress() {
      const suggestionIsSelectable =
        dropdownIsVisible.value && suggestionsExist.value && !loading.value
      if (multiselect.value) {
        if (suggestionIsSelectable) {
          selectSuggestion(focusedSuggestionIndex.value)
        }
      } else {
        if (suggestionIsSelectable) {
          selectSuggestion(focusedSuggestionIndex.value)
        }
        emit('submit', trimmedQuery.value)
      }
    }

    function selectSuggestion(index: number) {
      let selectedSuggestion

      if (multiselect.value) {
        const focusedSuggestion =
          internalSuggestions.value[focusedSuggestionIndex.value]

        // if it already exists, ignore it
        if (
          !internalMultiselectValues.value.some(
            v => v.id === focusedSuggestion.id
          )
        ) {
          internalMultiselectValues.value.push(focusedSuggestion)
        }
        selectedSuggestion = focusedSuggestion
        setQuery('')
        emit('multiselect-change', internalMultiselectValues.value)
      } else {
        selectedSuggestion = internalSuggestions.value[index]
        const { name } = selectedSuggestion
        setQuery(name)
      }

      hideDropdown()
      emit('select-suggestion', selectedSuggestion)
    }

    const trimmedQuery = computed(() => query.value.trim())

    const requestSuggestions = debounce(() => {
      emit('request-suggestions', trimmedQuery.value)
    }, inputDebounce.value)

    function onKeyDown() {
      if (!dropdownIsVisible.value) {
        showDropdown()
        return
      }
      focusAdjacentSuggestion(1)
    }

    function onKeyUp() {
      focusAdjacentSuggestion(-1)
    }

    const internalMultiselectValuesExist = computed(
      () => internalMultiselectValues.value.length > 0
    )

    function removeMultiselectValue(value: AutocompleteSuggestion) {
      internalMultiselectValues.value = internalMultiselectValues.value.filter(
        v => v.id !== value.id
      )
      emit('multiselect-change', internalMultiselectValues.value)
      focusInput()
    }

    function onBackspacePress() {
      if (query.value.length === 0) {
        internalMultiselectValues.value.pop()
        emit('multiselect-change', internalMultiselectValues.value)
      }
    }

    function setQuery(text: string) {
      query.value = text
      emit('input', trimmedQuery.value)
    }

    function handleNewSuggestions(newSuggestions: AutocompleteSuggestion[]) {
      internalSuggestions.value = newSuggestions
      loading.value = false
    }

    function focusInput() {
      const inputRef = multiselect.value
        ? multiselectInputTemplateRef
        : singleSelectInputTemplateRef
      if (inputRef.value) {
        inputRef.value.focus()
      }
    }

    const multiselectWrapperClasses = computed(() => {
      function sizeClasses() {
        if (size.value === 'sm') {
          return ['tw-px-2', 'tw-py-1', 'tw-text-sm']
        }

        return ['tw-px-3', 'tw-py-1.5', 'tw-text-lg']
      }
      return [...sizeClasses()]
    })

    const multiselectInputClasses = computed(() => {
      if (!multiselectValues.value.length) {
        return ['tw-grow']
      }
      return []
    })

    const multiselectInputStyle = computed(() => {
      if (!multiselectValues.value.length) {
        return {}
      }
      const width = 40 + query.value.length * 5

      return {
        width: `${width}px`
      }
    })

    function onInputWrapperClick() {
      if (!multiselectInputTemplateRef.value) {
        return
      }
      multiselectInputTemplateRef.value.focus()
    }

    const multiselectInputLimitReached = computed(
      () => internalMultiselectValues.value.length >= multiselectMax.value
    )

    const icons = {
      remove: faTimes
    }

    return {
      onInputWrapperClick,
      multiselectInputStyle,
      multiselectInputClasses,
      query,
      hideDropdown,
      showDropdown,
      onInput,
      onFocus,
      onClickOutside,
      getSuggestionClass,
      focusAdjacentSuggestion,
      dropdownIsVisible,
      internalSuggestions,
      focusedSuggestionIndex,
      selectSuggestion,
      suggestionIsFocused,
      onEnterKeyPress,
      onKeyDown,
      onKeyUp,
      internalMultiselectValues,
      internalMultiselectValuesExist,
      icons,
      removeMultiselectValue,
      multiselectInputTemplateRef,
      singleSelectInputTemplateRef,
      dropdownTemplateRef,
      suggestionRefs,
      onBackspacePress,
      loading,
      multiselectWrapperClasses,
      id,
      multiselectInputLimitReached
    }
  },
  i18n: defineComponentTranslations({
    suggestions_loading: {
      en: 'Loading...',
      el: 'Φόρτωση...'
    }
  })
})
