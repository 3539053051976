















import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import CInputAutocomplete from '~/components/shared/configurable/form/input/CInputAutocomplete.vue'
import { debounce } from '~/utils/function'
import { isCancelError } from '~/utils/http'
import { AxiosError } from 'axios'
import { DEBOUNCE_SWIFT } from '~/constants/debounce'
import { useDep } from '~/compositions/dependency-container'
import GeocodingService from '~/services/location/geocoding/GeocodingService'
import { GeocodingSearchLocation } from '~/models/location/geocoding'
import { useLogger } from '~/compositions/logger'
import { AutocompleteSuggestion } from '~/models/form/autocomplete'
import { useI18n } from '~/compositions/i18n'
import { getRandomArrayItem } from '~/utils/array'
import { InputSize } from '~/models/app/input'

export default defineComponent({
  model: {
    prop: 'selectedLocations',
    event: 'multiselect-change'
  },
  components: { CInputAutocomplete },
  props: {
    value: {
      type: String,
      default: ''
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    label: {
      type: String,
      default: ''
    },
    selectedLocations: {
      type: Array as PropType<GeocodingSearchLocation[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { value, selectedLocations } = toRefs(props)

    const locations = ref<GeocodingSearchLocation[]>([])
    const internalSelectedLocations = ref<GeocodingSearchLocation[]>(
      selectedLocations.value
    )
    const query = ref(value.value)

    const geocodingService = useDep(GeocodingService)
    const logger = useLogger()
    const { t } = useI18n()

    watch(value, newValue => (query.value = newValue))
    watch(
      selectedLocations,
      newSelectedLocations =>
        (internalSelectedLocations.value = newSelectedLocations)
    )

    const search = debounce(async () => {
      try {
        const { locations: geocodingLocations } = await geocodingService.search(
          query.value
        )
        locations.value = geocodingLocations
      } catch (error) {
        if (!isCancelError(error as AxiosError)) {
          logger.captureError(error as Error)
        }
      }
    }, DEBOUNCE_SWIFT)

    const suggestions: ComputedRef<AutocompleteSuggestion[]> = computed(() =>
      locations.value.map(l => ({
        id: l.id,
        name: l.name
      }))
    )

    function onRequestSuggestions(text: string) {
      query.value = text
      search()
    }

    function onMultiselectChange(suggestions: AutocompleteSuggestion[]) {
      const fullLocations = []
      function getFullLocation(
        collection: GeocodingSearchLocation[],
        suggestion: AutocompleteSuggestion
      ) {
        return collection.find(item => item.id === suggestion.id)
      }
      for (const suggestion of suggestions) {
        const fullLocation =
          getFullLocation(internalSelectedLocations.value, suggestion) ||
          getFullLocation(locations.value, suggestion)
        if (fullLocation) {
          fullLocations.push(fullLocation)
        }
      }
      internalSelectedLocations.value = fullLocations
      emit('multiselect-change', fullLocations)
    }

    function onSelectSuggestion(suggestion: AutocompleteSuggestion) {
      emit(
        'select',
        locations.value.find(l => l.id === suggestion.id)
      )
    }

    const multiselectValues = computed(() =>
      selectedLocations.value.map(l => ({
        id: l.id,
        name: l.name
      }))
    )

    const defaultPlaceholder = computed(() => {
      if (process.server) {
        return ''
      }
      if (internalSelectedLocations.value.length === 0) {
        const randomCity = getRandomArrayItem([
          t('thessaloniki::location'),
          t('athens::location'),
          t('rhodos::location'),
          t('chania::location')
        ])

        return `${t('e.g.')} ${randomCity}`
      }
      return ''
    })

    return {
      defaultPlaceholder,
      locations,
      onRequestSuggestions,
      onSelectSuggestion,
      suggestions,
      onMultiselectChange,
      multiselectValues
    }
  }
})
